// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-configuration-mdx": () => import("./../src/pages/configuration.mdx" /* webpackChunkName: "component---src-pages-configuration-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-installation-mdx": () => import("./../src/pages/installation.mdx" /* webpackChunkName: "component---src-pages-installation-mdx" */),
  "component---src-pages-mixins-mdx": () => import("./../src/pages/mixins.mdx" /* webpackChunkName: "component---src-pages-mixins-mdx" */),
  "component---src-pages-recipes-mdx": () => import("./../src/pages/recipes.mdx" /* webpackChunkName: "component---src-pages-recipes-mdx" */),
  "component---src-pages-usage-mdx": () => import("./../src/pages/usage.mdx" /* webpackChunkName: "component---src-pages-usage-mdx" */),
  "component---src-pages-using-in-production-mdx": () => import("./../src/pages/using-in-production.mdx" /* webpackChunkName: "component---src-pages-using-in-production-mdx" */),
  "component---src-templates-prop-js": () => import("./../src/templates/prop.js" /* webpackChunkName: "component---src-templates-prop-js" */)
}

